import React, {useContext, useEffect, useState} from "react";
import axios from 'axios';
import {ce_default_url} from "../../context/firebase-config";
import {authContext} from "../../context/authContext";

const PortfolioUploadForm = ({project, onClose, onSuccess, onError}) => {
    const user = useContext(authContext);
    const [formData, setFormData] = useState(new FormData());
    const [apiKey, setApiKey] = useState(null);
    const regex=/^[-a-zA-Z0-9_]{2,32}$/;
    const [isPortfolioNameValid, setIsPortfolioNameValid] = useState(true);

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    setApiKey(token.claims.api_key)
                } else {
                    console.error('api key not available for user')
                }
            })
    }, [user]);

    const handleChange = (event) => {
        const {name, value, files} = event.target;
        if (files) {
            formData.append(name, files[0]);
        } else {
            setIsPortfolioNameValid(!regex.test(value));
            console.log(`Handling change. Is Portfolio '${value}' valid? ${isPortfolioNameValid}`);
            formData.append(name, value);
        }
        setFormData(new FormData(event.target.form));
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        console.log(`Handling submit. Is portfolio name valid? ${isPortfolioNameValid}`)
        if (!isPortfolioNameValid) {
            onError("Invalid portfolio name. Valid portfolio names are between 2 and 32 characters comprised of letters, numbers, underscores, hyphens, and no spaces.")
        }
        formData.append('api_key', apiKey);
        formData.append('project', project);
        const url = `${ce_default_url}/api/portfolios/financial/add_portfolio`;
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(url, formData, config)
            .then((response) => {
                console.log(response.data);
                onSuccess('Portfolio uploaded successfully')
            })
            .catch(error => {
                if (error.response) {
                    console.log(`[${error.response.status}] ${error.toJSON()}`);
                    onError(JSON.stringify(error.response.data))
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

        onClose()
    }

    return (
        <form onSubmit={(event) => handleSubmit(event)}>
            <div className="modal-header bg-black" style={{border: "1px solid grey"}}>
                <h5 className={"modal-title"} style={{color: "#ffffff"}}>Create New Portfolio</h5>
                <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="modal-body form-group bg-black" style={{border: "1px solid grey"}}>
                <p className="mb-1" style={{color: "#ffffff", fontWeight: "455", fontSize: "1rem"}}>Portfolio Name</p>
                <input className="form-control" type="input" name="portfolio_name" placeholder="Example-Portfolio-Name"
                       pattern="^[-a-zA-Z0-9_]{2,32}$" required="" onChange={handleChange}/>
                <p style={{color: "#808080", fontWeight: "400"}}>
                    <small>Valid portfolio names are between 2 and 32 characters comprised of letters, numbers,
                        underscores, hyphens, and no spaces.</small>
                </p>

                <p className="mt-4 mb-1" style={{color: "#ffffff", fontWeight: "455", fontSize: "1rem"}}>Portfolio Asset
                    CSV File</p>
                <input className="form-control" type="file" name="asset" accept=".csv" required=""
                       onChange={handleChange}/>
                <p style={{color: "#808080", fontWeight: "400"}}>
                    <small>The <a target="_blank" href="https://developers.sustglobal.com/user-guide">Sust Global
                        Developer Center</a> contains additional resources that may assist in this process.</small>
                </p>
                {/*<input type="hidden" name={"api_key"} value={"zmHxSYnO62xzcG81m4Ug"}/>*/}
            </div>
            <div className="modal-footer bg-black" style={{border: "1px solid grey"}}>
                <button className={"btn btn-primary"} type="submit">Submit</button>
                <button className={"btn btn-secondary"} onClick={onClose}>Close</button>
            </div>
        </form>
    );
}

export default PortfolioUploadForm;